import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-menu-home',
  templateUrl: './menu-home.component.html',
  styleUrls: ['./menu-home.component.scss']
})
export class MenuHomeComponent implements OnInit {

  menuData;
  menuData1;
  showSubcategory = -1;
  private menuUrl: string;
  urlPath: any;

  constructor(private http: HttpClient, public route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.getRestaurant();
  }

  getJsonData(): void {
    this.http.get(this.menuUrl).subscribe(data => {
        console.log('this is json data', data);
        this.menuData1 = data;
        this.menuData = this.menuData1.categories;
        this.setJson();
      }, error => {
        console.log('an error occurred while fetching data', error);
      }
    );

  }

  setJson(): void {
    for (const cat of this.menuData) {
      cat.subcategories = _.filter(this.menuData1.subcategories, {category: cat.id});
      console.log('these are subcategories', cat.subcategories);
      for (const subcat of cat.subcategories) {
        subcat.dishes = _.filter(this.menuData1.dishes, {subcategory: subcat.id});
      }
      cat.dishes = _.filter(this.menuData1.dishes, {category: cat.id});
    }
  }

  getRestaurant(): void {
    this.urlPath = this.route.snapshot.params.id;
    this.menuUrl = '/assets/json/' + this.route.snapshot.params.id + '.json';
    this.getJsonData();
  }

  showSub(index: any): void {
    if (this.showSubcategory === index) {
      this.showSubcategory = -1;
    } else {
      this.showSubcategory = index;
    }
  }

}
