<div class="container" *ngIf="menuData1">
  <div *ngIf="menuData1.misc.type === 0">
    <div class="row justify-content-center text-center my-5">
      <div class="col-md-7">
        <img class="animate__animated animate__zoomIn" src="/assets/img/logo.png" style="width: 30%;height: auto">
        <h2 class="sandoz-head" [ngStyle]="{'color': menuData1.misc.color}">{{menuData1.misc.title}}</h2>
      </div>
    </div>
    <p class="text-center mb-5">A melody of fresh ingredients and a hearty celebration
      of modern international cuisine.</p>
    <div  class="cat-cont card" [ngStyle]="{'border': '1px solid ' + menuData1.misc.color}"  *ngFor="let category of menuData ,let i = index"  (click)="showSub(i)">
      <div  matRipple>
        <div    class="category" [ngStyle]="{'color': menuData1.misc.color}">
          <span class="cat-name">{{category.name}}</span>
          <span class="float-end" *ngIf="showSubcategory !== i">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
          </svg>
        </span>
          <span class="float-end" *ngIf="showSubcategory === i">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
          </svg>
        </span>
        </div>
      </div>
      <div class="animate animate__animated animate__fadeIn" *ngIf="showSubcategory === i">
        <hr class="m-3">
        <div   class=" dish-card" *ngFor="let sub of category.subcategories">
          <div class="sub">{{sub.name}}</div>
          <div *ngFor="let dish of sub.dishes" class="d-flex justify-content-between">
            <div class="dish">
            <span>
            <svg *ngIf="dish.type === 'v'" style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="#28590C" d="M20 4V20H4V4H20M22 2H2V22H22V2M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12 15.31 6 12 6Z" />
            </svg>
              <svg *ngIf="dish.type === 'n'" style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="#DC1E0B " d="M20 4V20H4V4H20M22 2H2V22H22V2M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12 15.31 6 12 6Z" />
              </svg>

              <span *ngIf="dish.type === 'v-n'">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="#28590C" d="M20 4V20H4V4H20M22 2H2V22H22V2M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12 15.31 6 12 6Z" />
            </svg>
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="#DC1E0B " d="M20 4V20H4V4H20M22 2H2V22H22V2M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12 15.31 6 12 6Z" />
              </svg>
              </span>
            </span>
              {{dish.name}}
            </div>
            <div class="dish-price">{{dish.price.length > 0 || dish.price > 0 ? '&#8377;' + dish.price : '-'}} </div>
          </div>
        </div>
        <div *ngIf="category.subcategories.length === 0"   class=" dish-card">
          <div  *ngFor="let dish of category.dishes"  class="d-flex justify-content-between">
            <div class="dish">
            <span>
            <svg *ngIf="dish.type === 'v'" style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="#28590C" d="M20 4V20H4V4H20M22 2H2V22H22V2M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12 15.31 6 12 6Z" />
            </svg>
              <svg *ngIf="dish.type === 'n'" style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="#DC1E0B " d="M20 4V20H4V4H20M22 2H2V22H22V2M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12 15.31 6 12 6Z" />
              </svg>
              <span *ngIf="dish.type === 'v-n'">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="#28590C" d="M20 4V20H4V4H20M22 2H2V22H22V2M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12 15.31 6 12 6Z" />
            </svg>
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="#DC1E0B " d="M20 4V20H4V4H20M22 2H2V22H22V2M12 6C8.69 6 6 8.69 6 12S8.69 18 12 18 18 15.31 18 12 15.31 6 12 6Z" />
              </svg>
              </span>

            </span>
              {{dish.name}}</div>
            <div class="dish-price">{{dish.price.length > 0 || dish.price > 0 ? '&#8377;' + dish.price : '-'}} </div>
          </div>
        </div>

      </div>
    </div>
    <div *ngIf="this.route.snapshot.params.id !== 'cafe-sandoz'" class="row mb-5 mt-5">
      <div class="col-lg-3 col-md-6 mb-5 mb-md-5">
        <div class="ftco-footer-widget mb-4">
          <h2 class="ftco-heading-2" style="padding: 10px">About Us</h2>
          <div style="color: #6d6d6d;padding: 10px">
            A BRIEF HISTORY About SANDOZ

            After migrating from Pakistan in the year 1947,Sardar Jagir Singh Ji started with a small dhaba in 1950 by the name of Calcutta Dhaba in Karol Bagh,
            Delhi. His passion for bodybuilding made him physically very strong and gave him the name 'Sando' and everyone started calling him
            'Sando Pahalwan'. He worked on a Dhaba for 40 years to earn bread and butter of the family, but a sudden cardiac arrest put him to rest in 1993. Having left his education midway, it was his son,
            Gurmeet Singh who decided to take over the dhaba to continue earning for his family. In the year 1994-95, Gurmeet first changed the name of the dhaba from Calcutta Dhaba'
            to 'Sandoz' in his father's honour and 'Sandoz Restaurant' was born. Through his hard work and dedication SANDOZ started growing. He was later joined by
            his brother Inderpreet Singh in 1996. A roadside dhaba was soon transformed into a restaurant and then a multi-location food chain.
            Later, they were joined by their sons' Angad and Raunaq. Therefore, infusing young blood into experience, Team Sandoz was ready
            and planned to create havoc by opening restaurants, banquets and lounges all accross Delhi. SANDOZ is not only
            famous for its delicious food but also is known as a place for having a gala time with friends and family. Accepting
            changes and by making the necessary changes with time, with love of our customers and blessings of almighty, Sandoz has
            already won the hearts of the city.

            Here is a list of our outlets

            <ul style="list-style: none;margin-top: 20px;color: #6d6d6d">
              <li>1. Sandoz, L-block,Connaught Place</li>
              <li>2. Sandoz, P-block,Connaught Place</li>
              <li>3. Sandoz, Lajpat Nagar</li>
              <li>4. Sandoz, Nehru Place</li>
              <li>5. Sandoz, Ashok Vihar</li>
              <li>6. Sandoz, Rajouri</li>
              <li>7. Sandoz, Paschim Vihar</li>
              <li>8. Sandoz, Karol Bagh</li>
              <li>9. Sandoz, D.B.G. Road</li>
              <li>10. Sandoz, Jail Road</li>
              <li>11. Sandoz B2 Banquet ,Lawrence Road</li>
              <li>12. Freedom Roost by Sandoz, Satguru ram Singh Marg</li>
              <li>13. The Luggage Room,M-Block,Connaught Place</li>
              <li>14. Five Elements by Sandoz,Janakpuri</li>
              <li>15. Mosaic by Sandoz,Wazirpur</li>
              <li>16. Celebrationz by Sandoz,Pitampura</li>
              <li>17. The Terrace Sandoz, Pitampura</li>
              <li>18. Chandelier By Sandoz,Rama Road</li>
              <li> 19. Sandoz FFC,Moti Nagar</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="text-center social">
        <a href="https://www.facebook.com/sandozcp/" class="bg-icon" target="_blank">
          <svg class="icon"  viewBox="0 0 24 24">
            <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
          </svg>
        </a>
        <a href="https://www.instagram.com/thesandoz/" target="_blank">
          <svg class="icon" style="margin-left: 15px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
          </svg>
        </a>
      </div>

    </div>

    <div *ngIf="menuData1" class="footer text-center mt-2" [ngStyle]="{'background': menuData1.misc.color}">
      &#169; Sandoz
    </div>



  </div>

  <div *ngIf="menuData1.misc.type === 1" style="z-index: 99">
    <pdf-viewer [src]="'assets/pdf/' +  urlPath + '.pdf'"
                [render-text]="true"
               [fit-to-page] = true

                [zoom-scale]="'page-width'"
                style="display: block;"
    ></pdf-viewer>

  </div>

<!--  <div *ngIf="menuData1.misc.type === 2" style="z-index: 99">-->
<!--    <pdf-viewer [src]="'assets/pdf/cafe-sandoz.pdf'"-->
<!--                [render-text]="true"-->
<!--                [fit-to-page] = true-->

<!--                [zoom-scale]="'page-width'"-->
<!--                style="display: block;"-->
<!--    ></pdf-viewer>-->

<!--  </div>-->

</div>

